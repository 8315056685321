import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { handleRef } from '../../utils/refUtils';

import { RefProps } from './Ref';


export default class RefFindNode extends React.Component<RefProps, never> {

  static displayName = 'RefFindNode';

  prevNode: Node | null = null;


  public componentDidMount() {
    this.prevNode = ReactDOM.findDOMNode(this);

    handleRef(this.props.innerRef, this.prevNode);
  }


  public componentDidUpdate(prevProps: Readonly<RefProps>) {
    const currentNode = ReactDOM.findDOMNode(this);

    if (this.prevNode !== currentNode) {
      this.prevNode = currentNode;
      handleRef(this.props.innerRef, currentNode);
    }

    if (prevProps.innerRef !== this.props.innerRef) {
      handleRef(this.props.innerRef, currentNode);
    }
  }


  public componentWillUnmount() {
    handleRef(this.props.innerRef, null);

    delete this.prevNode;
  }


  public render() {
    const { children } = this.props;

    return children;
  }

}
