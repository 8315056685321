import * as React from 'react';


function CloudyNight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox={'0 0 512.003 512.003'} width={'1em'} height={'1em'} {...props}>
      <path
        d={'M504.576 170.054c-5.056-3.264-11.52-3.36-16.672-.32-6.816 4-15.008 6.112-23.584 6.112-26.464 0-48-21.536-48-48 0-8.608 2.112-16.768 6.112-23.584 3.04-5.184 2.912-11.616-.32-16.704-3.264-5.056-9.152-7.904-15.008-7.328-49.472 4.672-86.784 45.792-86.784 95.616 0 52.928 43.072 96 96 96 49.824 0 90.944-37.312 95.616-86.816.544-5.952-2.272-11.776-7.36-14.976z'}
        fill={'#B0BEC5'}
      />
      <path
        d={'M412.864 239.878C397.92 165.862 333.088 111.846 256 111.846c-64.032 0-121.504 38.112-146.688 96.032C48.8 209.318 0 258.982 0 319.846c0 61.76 50.24 112 112 112h304c52.928 0 96-43.072 96-96 0-53.984-45.536-97.344-99.136-95.968z'}
        fill={'#E3F2FD'}
      />
    </svg>
  );
}

const MemoCloudyNight = React.memo(CloudyNight);
export default MemoCloudyNight;
