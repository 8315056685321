import * as React from 'react';

import { createPortal } from 'react-dom';

import { invoke } from 'lodash';

import Ref from '../Ref/Ref';
import { handleRef } from '../../utils/refUtils';
import isBrowser from '../../utils/isBrowser';


/* --------
 * Component Props
 * -------- */
export interface PortalInnerProps extends StrictPortalInnerProps {
  [key: string]: any;
}

export interface StrictPortalInnerProps {
  /** Primary Content */
  children: React.ReactNode;

  /** Use to get the inner node ref */
  innerRef?: React.Ref<HTMLElement>;

  /** The node where the Portal should mount */
  mountNode?: HTMLElement;

  /** Called when the PortalInner is mounted on the DOM */
  onMount?: (nothing: null, data: PortalInnerProps) => void;

  /** Called when the PortalInner is unmounted from the DOM */
  onUnmount?: (nothing: null, data: PortalInnerProps) => void;
}


/* --------
 * Component Definition
 * -------- */
export default class PortalInner extends React.Component<PortalInnerProps, never> {

  public componentDidMount() {
    invoke(this.props, 'onMount', null, this.props);
  }


  public componentWillUnmount() {
    invoke(this.props, 'onUnmount', null, this.props);
  }


  handleRef = (comp: HTMLElement) => {
    handleRef(this.props.innerRef, comp);
  };


  public render() {
    if (!isBrowser()) {
      return null;
    }

    const {
      children,
      mountNode = document.body
    } = this.props;

    return createPortal(
      <Ref innerRef={this.handleRef}>
        {children}
      </Ref>,
      mountNode
    );
  }
}
