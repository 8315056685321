import * as React from 'react';


function Snow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox={'0 0 512 512'} width={'1em'} height={'1em'} {...props}>
      <path
        d={'M416 144c-1.024 0-2.048 0-3.136.032C397.952 70.016 333.088 16 256 16c-64.032 0-121.504 38.112-146.688 96.032C48.8 113.472 0 163.136 0 224c0 61.76 50.24 112 112 112h304c52.928 0 96-43.072 96-96s-43.072-96-96-96z'}
        fill={'#E3F2FD'}
      />
      <g fill={'#CFD8DC'}>
        <path
          d={'M275.136 272c-4.448-7.68-14.24-10.304-21.856-5.856L208 292.288V240c0-8.832-7.168-16-16-16s-16 7.168-16 16v52.288l-45.28-26.144c-7.648-4.448-17.44-1.824-21.856 5.856-4.416 7.648-1.792 17.44 5.856 21.856L160.032 320l-45.312 26.144c-7.648 4.416-10.272 14.208-5.856 21.856 2.976 5.12 8.352 8 13.888 8 2.72 0 5.472-.672 8-2.144L176 347.712V400c0 8.832 7.168 16 16 16s16-7.168 16-16v-52.288l45.28 26.144a15.81 15.81 0 008 2.144c5.536 0 10.912-2.88 13.888-8 4.416-7.648 1.792-17.44-5.856-21.856L223.968 320l45.312-26.144c7.648-4.416 10.272-14.208 5.856-21.856zM417.568 442.144L399.968 432l17.6-10.144c7.648-4.416 10.272-14.176 5.856-21.856-4.416-7.68-14.208-10.304-21.856-5.856L384 404.288V384c0-8.832-7.168-16-16-16s-16 7.168-16 16v20.288l-17.568-10.144c-7.616-4.416-17.44-1.792-21.824 5.856-4.448 7.648-1.824 17.44 5.856 21.856L336.032 432l-17.6 10.144c-7.648 4.416-10.272 14.208-5.824 21.856 2.944 5.12 8.352 8 13.856 8 2.72 0 5.472-.672 8-2.144L352 459.712V480c0 8.832 7.168 16 16 16s16-7.168 16-16v-20.288l17.568 10.144a15.81 15.81 0 008 2.144c5.504 0 10.912-2.88 13.856-8 4.416-7.648 1.792-17.44-5.856-21.856z'}
        />
      </g>
    </svg>
  );
}

const MemoSnow = React.memo(Snow);
export default MemoSnow;
