import * as React from 'react';


function Tornado(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox={'0 0 478.768 478.768'} width={'1em'} height={'1em'} {...props}>
      <path
        d={'M365.912 34.736C333.272 22.8 287.16 15.984 239.384 15.984c-98.688 0-176 27.584-176 62.784 0 7.104 3.104 13.856 9.472 20.672L83 110.224l-9.952 10.944c-6.4 7.008-9.664 14.272-9.664 21.6 0 10.016 5.408 19.232 16.576 28.16l10.624 8.512-6.688 11.84c-3.008 5.312-4.512 10.528-4.512 15.488 0 21.76 24.768 36.8 39.52 43.84l10.336 4.928-1.312 11.328L111.48 270v.032l15.904.8c0 18.56 14.24 32.352 26.208 40.608l8.512 5.888-1.856 10.144c-.448 2.4-.864 4.8-.864 7.296 0 16.48 9.28 32.096 26.112 44l7.552 5.344-1.44 13.216c-.224 36.736 28.48 65.44 63.776 65.44s64-28.704 64-64l-1.664-14.656 7.52-5.344c16.832-11.904 26.112-27.52 26.112-44 0-8.352-2.464-16.576-7.328-24.384l-5.44-8.672 5.632-8.608c4.768-7.168 7.168-14.688 7.168-22.336 0-4.672-1.408-9.856-4.416-16.192l-5.184-10.976 9.152-7.968c10.912-9.44 16.448-19.136 16.448-28.864 0-4.96-1.536-10.176-4.512-15.488l-6.656-11.84 10.592-8.512c11.168-8.928 16.576-18.144 16.576-28.16 0-3.2-.672-6.464-2.048-9.984l-5.088-12.864 12-6.912c18.016-10.336 27.136-21.856 27.136-34.24 0-22.624-31.008-37.312-49.472-44.032z'}
        fill={'#90CAF9'}
      />
      <path
        d={'M431.384 78.768c0-105.024-384-105.024-384 0 0 11.84 5.12 22.336 13.824 31.616-10.976 12.032-13.824 23.744-13.824 32.384 0 15.808 8.48 29.376 22.56 40.64-4.16 7.392-6.56 15.232-6.56 23.36 0 23.488 18.464 43.904 48.64 58.272-.192 1.92-.64 3.744-.64 5.728 0 20.992 12.64 39.68 33.12 53.824-.608 3.328-1.12 6.688-1.12 10.176 0 22.592 12.48 42.656 32.864 57.088-.192 2.336-.864 4.48-.864 6.912 0 44.128 35.904 80 80 80 44.128 0 80-35.872 80-80 0-2.432-.672-4.576-.864-6.912 20.384-14.432 32.864-34.496 32.864-57.088 0-11.552-3.424-22.688-9.76-32.832 6.272-9.536 9.76-20.064 9.76-31.168 0-7.904-2.4-15.552-5.952-23.04 13.696-11.872 21.952-25.696 21.952-40.96 0-8.128-2.4-15.936-6.592-23.36 14.112-11.264 22.592-24.832 22.592-40.64 0-5.472-1.152-10.752-3.2-15.872 21.76-12.48 35.2-28.576 35.2-48.128zm-176 368c-23.52 0-43.04-17.024-47.136-39.392 14.304 4.64 30.144 7.392 47.136 7.392s32.832-2.752 47.136-7.392c-4.096 22.368-23.616 39.392-47.136 39.392zm0-64c-40.224 0-74.24-18.944-79.04-42.464 18.592 6.496 39.84 10.464 63.04 10.464 37.728 0 70.88-9.856 94.112-25.6 1.056 2.944 1.888 6.08 1.888 9.6 0 26.016-36.64 48-80 48zm80-112c0 22.72-39.424 48-96 48-51.744 0-88.736-21.152-94.752-42.144 23.072 6.304 49.536 10.144 78.752 10.144 43.968 0 82.496-8.128 110.848-21.536.512 1.856 1.152 3.712 1.152 5.536zm16-64c0 19.584-49.888 48-128 48-78.144 0-128-28.416-128-48 0-2.56 1.088-4.864 2.272-7.072 33.92 15.136 80.512 23.072 125.728 23.072s91.808-7.936 125.728-23.072c1.184 2.208 2.272 4.544 2.272 7.072zm16-64c0 19.584-56.096 48-144 48s-144-28.416-144-48c0-4 2.944-8.64 8.032-13.344 36.96 19.328 94.592 29.344 151.968 29.344 45.856 0 91.84-6.4 127.392-18.848.256 1.024.608 2.144.608 2.848zm-128-16c-96.192 0-160-28.896-160-48s63.808-48 160-48 160 28.896 160 48-63.808 48-160 48z'}
        fill={'#2196F3'}
      />
    </svg>
  );
}

const MemoTornado = React.memo(Tornado);
export default MemoTornado;
