/**
 * AutoControlled Component is a porting of SemanticUI ModernAutoControlled Component.
 * It's a component that extends the default React Component to give the advantage to have
 * some props that may be auto controlled from base component.
 *
 * SemanticUI choose the inheritance way over an HOC to have same advantage (copied by theirs repo):
 *
 * 1. Single Render
 *    Calling setState() does not cause two renders. Consumers and tests do not have to wait two
 *    renders to get state.
 *    See www.react.run/4kJFdKoxb/27 for an example of this issue.
 *
 * 2. Simple Testing
 *    Using a HOC means you must either test the undecorated component or test through the decorator.
 *    Testing the undecorated component means you must mock the decorator functionality.
 *    Testing through the HOC means you can not simply shallow render your component.
 *
 * 3. Statics
 *    HOC wrap instances, so statics are no longer accessible. They can be hoisted, but this is more
 *    looping over properties and storing references.  We rely heavily on statics for testing and
 *    sub components.
 *
 * 4. Instance Methods
 *    Some instance methods may be exposed to users via refs.  Again, these are lost with HOC unless
 *    hoisted and exposed by the HOC.
 *
 * This porting will reduce some extra dependencies (like 'lodash') and can be used as a single
 * project dependencies, without use all SemanticUI repo
 */

import * as React from 'react';
import invariant from 'tiny-invariant';

import { getDefaultPropName, getAutoControlledStateValue } from './helpers';

import {
  PropsWithAutoControlled,
  StateWithAutoControlled,
  InitialStateGetter,
  AutoControlledComponentConstructor,
  AutoControlledMethods
} from './interfaces';

/* --------
 * AutoControlled Component Definition
 * -------- */
class AutoControlledComponent<AP = {}, P = {}, S = {}, SS = any>
  extends React.Component<PropsWithAutoControlled<AP, P>, StateWithAutoControlled<AP, P, S>, SS>
  implements AutoControlledMethods<AP, P, S> {

  getInitialAutoControlledState?: InitialStateGetter<AP, P, S>;


  static getDerivedStateFromProps(
    props: PropsWithAutoControlled,
    state: StateWithAutoControlled
  ): Partial<StateWithAutoControlled> {
    /** Get Helpers from State */
    const {
      autoControlledProps,
      getAutoControlledStateFromProps
    } = state;

    /** Compute next State using autoControlledProps */
    const newStateFromProps: Partial<StateWithAutoControlled> = {};

    autoControlledProps.forEach((propName) => {
      /** If prop is defined, set using prop value */
      if (props[propName] !== undefined) {
        newStateFromProps[propName] = props[propName];
      }
    });

    /** If a custom hook function has been defined, use to compute extra state props */
    if (typeof getAutoControlledStateFromProps === 'function') {
      const computedState = getAutoControlledStateFromProps(props, {
        ...state,
        ...newStateFromProps
      });

      /** Return merged states */
      return {
        ...newStateFromProps,
        ...computedState
      };
    }

    return newStateFromProps;

  }


  constructor(props: PropsWithAutoControlled<AP, P>, context: any) {
    super(props, context);

    /** Get Component Configuration */
    const {
      autoControlledProps,
      getAutoControlledStateFromProps,
      defaultProps,
      displayName,
      name,
      getDerivedStateFromProps
    } = (this.constructor as AutoControlledComponentConstructor<AP, P, S>);

    /** When build is not in production mode, check configuration */
    if (process.env.NODE_ENV !== 'production') {
      /** Assert autoControlledProps is an Array */
      invariant(
        Array.isArray(autoControlledProps),
        'AutoControlled Component must specify the static autoControlledProps field as an array of strings'
      );

      /** Assert getDerivedState from Props has not been override */
      invariant(
        /**
         * Next line must be silence using ts-ignore
         * because TypeScript couldn't find any overlap.
         * As the AutoControlledComponent is a base class and
         * must be used as an extension for component, getDerivedStateFromProps
         * static function could be incorrectly overridden
         */
        // @ts-ignore
        getDerivedStateFromProps === AutoControlledComponent.getDerivedStateFromProps,
        [
          `AutoControlled Component named '${displayName || name}' must specify a static`,
          'getAutoControlledStateFromProps() instead of getDerivedStateFromProps()'
        ].join(' ')
      );

      /**
       * Prevent autoControlled Props definition in defaultProps
       * static field of class.
       * When setting state, auto controlled props values will always
       * win on static default props.
       * As autoControlledProps are transferred to state, they could be
       * declared directly into state object
       */
      if (defaultProps) {
        const illegalDefaults = autoControlledProps.filter((propName) => (
          defaultProps[propName] !== undefined
        ));

        invariant(
          illegalDefaults.length === 0,
          [
            'defaultProps cannot be set for autoControlledProps. They can be set as defaults, setting',
            'state in the constructor or using ES7 property initializer',
            `See ${displayName || name} props: "${illegalDefaults.join(', ')}"`
          ].join(' ')
        );
      }

      /**
       * Prevent using defaultProps in autoControlledProps
       * Default props are automatically handled by AutoControlled Components.
       */
      const illegalAutoControlled = autoControlledProps.filter((propName) => (propName as string).startsWith('default'));

      invariant(
        illegalAutoControlled.length === 0,
        [
          'Do not add any default props to autoControlledProps.',
          'Default props are automatically handled.,' +
          `See ${displayName || name} props: "${illegalAutoControlled.join(', ')}"`
        ].join(' ')
      );
    }

    /**
     * Set initial State of AutoControlled Component:
     *  1. Invoke the getInitialAutoControlledState function passing props if exists
     *  2. Copy Auto Controlled Props to state
     *     Also look for the default prop for any auto controlled props
     */
    const state: Partial<StateWithAutoControlled<AP, P, S>> = typeof this.getInitialAutoControlledState === 'function'
      ? this.getInitialAutoControlledState(props)
      : {};

    const initialAutoControlledState: Partial<AP> = {};

    autoControlledProps.forEach((propName) => {
      /** Set the AutoControlledValue */
      initialAutoControlledState[propName] = getAutoControlledStateValue(propName, props, state, true);

      /** In non-production build check prop is not multi declared */
      if (process.env.NODE_ENV !== 'production') {
        const defaultPropName = getDefaultPropName(propName);

        invariant(
          !(props[defaultPropName] !== undefined && props[propName] !== undefined),
          [
            `${displayName || name} prop "${propName}" is auto controlled.`,
            ` Specify either ${defaultPropName} or ${propName}, but not both.`
          ].join(' ')
        );
      }
    });

    /**
     * Update the State.
     * This must be casted to Partial Auto Controlled State
     * because state in React Component
     * is assumed to be readonly also into constructor function
     */
    (this.state as Partial<StateWithAutoControlled<AP, P, S>>) = {
      ...state,
      ...initialAutoControlledState,
      autoControlledProps,
      getAutoControlledStateFromProps
    };
  }

}

export default AutoControlledComponent;
