import * as React from 'react';


export type PropsWithAs<P = {}> = P & { as?: React.ElementType | string } & { [key: string]: any };


/**
 * Compute the React Element Type used to render a Component.
 *
 * First look to get the right type is checking props or default props
 * searching for `as` key.
 *
 * If no `as` key exists, function will use the `getDefault` function
 * to compute the Element Type.
 *
 * getElementType will fallback to `div` element.
 *
 * @param Component A React Component Type
 * @param props The ReactElement Props Object
 * @param getDefault A function that must return an Element Type
 */
export default function getElementType<P = {}>(
  Component: React.ComponentType<PropsWithAs<P>>,
  props: PropsWithAs<P>, getDefault?: ((props: PropsWithAs<P>) => React.ElementType | undefined)
): React.ElementType | string {

  // Get Component defaultProps
  const { defaultProps } = Component;

  // Return user defined ElementType
  if (props.as && props.as !== defaultProps?.as) {
    return props.as;
  }

  // Use the getDefault function to calculate the element
  if (typeof getDefault === 'function') {
    const elementType = getDefault(props);

    if (elementType) {
      return elementType;
    }
  }

  // If props include href property, return an anchor element
  if (props.href) {
    return 'a';
  }

  // Return the defaultProps, or fallback to div element
  return defaultProps?.as || 'div';

}
