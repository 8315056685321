import * as React from 'react';
import clsx from 'clsx';

import kindOf from 'kind-of';

import { isNil, uniq } from 'lodash';


/* --------
 * Internal Type not Exported
 * -------- */
type ObjectOf<T> = { [key: string]: T };

type Props<P = {}> = P & ObjectOf<any>;

type ReactNode =
  | React.ReactChild
  | React.ReactNodeArray
  | React.ReactPortal
  | boolean
  | null
  | undefined;


/* --------
 * Types and Interfaces used by Factory Shorthand
 * -------- */
export type ShorthandComponent<P> = React.ElementType | React.ComponentType<P>;

export type ShorthandRenderFunctionValue<P> = (
  Component: ShorthandComponent<P>,
  props: P,
  children: React.ReactNode
) => React.ReactElement<P>;

export type ShorthandValue<P> = ReactNode | Props<P> | ShorthandRenderFunctionValue<P>;

export interface ShorthandProps<P> extends ObjectOf<any> {
  childKey?: React.Key | ((props: P) => React.Key);

  children?: React.ReactNode | null;

  className?: string;

  key?: React.Key;

  style?: React.CSSProperties;
}

export type MapShorthandPropsFunction<P> = (value: ShorthandValue<P>) => P;

export interface CreateShorthandOptions<P> {
  /** Auto Generate Key on Iteration */
  autoGenerateKey: boolean;

  /** Default Props to set */
  defaultProps?: P;

  /** Props that override computed ones */
  overrideProps?: P | ((props: P) => P);
}


/* --------
 * Main Create Shorthand Function
 * -------- */

/**
 * A more robust React.createElement.
 * It can create elements from primitive values.
 *
 * @param Component Component to Create
 * @param mapValueToProps Function to transform props
 * @param value Value to use
 * @param options Options to Apply
 */
export function createShorthand<P extends ShorthandProps<P> = {}>(
  Component: ShorthandComponent<P>,
  mapValueToProps: MapShorthandPropsFunction<P>,
  value: ShorthandValue<P>,
  options: CreateShorthandOptions<P>
): React.ReactElement<P> | null {

  /** If no value, return an empty component */
  if (isNil(value) || typeof value === 'boolean') {
    return null;
  }

  // Check value type
  const valueIsString = kindOf(value) === 'string';
  const valueIsNumber = kindOf(value) === 'number';
  const valueIsFunction = kindOf(value) === 'function';
  const valueIsReactElement = React.isValidElement(value);
  const valueIsPropsObject = kindOf(value) === 'object';
  const valueIsPrimitiveValue = valueIsString || valueIsNumber || kindOf(value) === 'array';

  // Check value validity
  if (!valueIsFunction && !valueIsReactElement && !valueIsPrimitiveValue && !valueIsPropsObject) {
    if (process.env.NODE_ENV !== 'production') {
      // tslint:disable-next-line:no-console
      console.error(
        [
          'Shorthand value must be a string|number|array|object|ReactElement|function.',
          'Use null|undefined|boolean for none.',
          `Received ${kindOf(value)}`
        ].join(' ')
      );
    }
    return null;
  }

  /** Build Props */
  const { defaultProps } = options;

  // Get user props
  const userProps: P =
    (valueIsReactElement && (value as React.ReactElement).props) ||
    (valueIsPropsObject && (value as P)) ||
    (valueIsPrimitiveValue && mapValueToProps(value)) ||
    {} as P;

  // Override Props
  let { overrideProps } = options;

  if (typeof overrideProps === 'function') {
    overrideProps = (overrideProps as ((props: P) => P))({
      ...defaultProps,
      ...userProps
    });
  }

  // Merge Props together
  const props: P = {
    ...defaultProps,
    ...userProps,
    ...overrideProps
  };

  // Merge className
  if (defaultProps?.className || overrideProps?.className || userProps.className) {
    const mergedClassNames = clsx(
      defaultProps?.className,
      userProps.className,
      overrideProps?.className
    );

    props.className = uniq(mergedClassNames.split(' ')).join(' ');
  }

  // Merge Style
  if (defaultProps?.style || overrideProps?.style || userProps.style) {
    props.style = {
      ...defaultProps?.style,
      ...userProps.style,
      ...overrideProps?.style
    };
  }

  // Create the Key
  if (isNil(props.key)) {
    const { childKey } = props;
    const { autoGenerateKey } = options;

    if (!isNil(childKey)) {
      // Apply and Consume the Child Key props
      props.key = typeof childKey === 'function' ? childKey(props) : childKey;
      delete props.childKey;
    }
    else if (autoGenerateKey && (valueIsString || valueIsNumber)) {
      props.key = (value as React.Key);
    }
  }

  // Create the element
  if (valueIsReactElement) {
    return React.cloneElement(value as React.ReactElement, props);
  }

  // Render as Component
  if (valueIsPrimitiveValue || valueIsPropsObject) {
    return (
      <Component {...props} />
    );
  }

  // Render as Function
  if (typeof value === 'function') {
    return value(Component, props, props.children);
  }

  // Fallback to null
  return null;
}


/* --------
 * Create a function to create shorthand factory
 * -------- */

/**
 * Get a callback function to be used to easily generate
 * a new component based on shorthand value
 *
 * @param Component The Component to Generate
 * @param mapValueToProps The function to map value to props
 */
export function createShorthandFactory<P extends ShorthandProps<P>>(
  Component: ShorthandComponent<P>,
  mapValueToProps: MapShorthandPropsFunction<P>
) {
  return function createFactoryElement(
    value: ShorthandValue<P> | ShorthandRenderFunctionValue<P>,
    options: CreateShorthandOptions<P>
  ) {
    return createShorthand(Component, mapValueToProps, value, options);
  };
}

export const createHTMLLabel = createShorthandFactory<JSX.IntrinsicElements['label']>('label', (val) => ({
  children: val
}));

export const createHTMLInput = createShorthandFactory<JSX.IntrinsicElements['input']>('input', (val) => ({
  type: val as string
}));

export const createHTMLParagraph = createShorthandFactory<JSX.IntrinsicElements['p']>('p', (val) => ({
  children: val
}));
