import { PropsWithAutoControlled, StateWithAutoControlled } from './interfaces';


export const getDefaultPropName = <P>(propName: keyof P): keyof P => (
  `default${(propName as string)[0].toUpperCase() + (propName as string).slice(1)}`
) as keyof P;


/**
 * Return the auto controlled state value for a prop.
 * The initial value is chosen using this order:
 *  - regular props
 *  - default props
 *  - `checked` defaults to false
 *  - `value` defaults to '' or [] if props.multiple
 *  - undefined
 *
 * @param propName The prop name
 * @param props The component props object
 * @param state The state object
 * @param includeDefaults Whether or not to heed the default props or initial state
 */
export function getAutoControlledStateValue<AP = {}, P = {}, S = {}>(
  propName: keyof AP,
  props: PropsWithAutoControlled<AP, P> & { multiple?: boolean },
  state: Partial<StateWithAutoControlled<AP, P, S>>,
  includeDefaults: boolean = false
): any {

  /** Check if regular props value exists */
  if (props[propName] !== undefined) {
    return props[propName];
  }

  /** Check if must include default values */
  if (includeDefaults) {
    /** Get the initial default value */
    const defaultPropName = getDefaultPropName(propName);

    if (props[defaultPropName] !== undefined) {
      return props[defaultPropName];
    }

    /** Get prop value from state */
    if (state && state[propName] !== undefined) {
      return state[propName];
    }
  }

  /**
   * As React doesn't allow change from uncontrolled to controlled components
   * set the default value based on propName if they are not present
   */
  if (propName === 'value') {
    return props.multiple ? [] : '';
  }

  if (propName === 'checked') {
    return false;
  }

  /** Else, will return undefined */
}
