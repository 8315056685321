import * as React from 'react';


function RainySnow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox={'0 0 512 512'} width={'1em'} height={'1em'} {...props}>
      <path
        d={'M416 144c-1.024 0-2.048 0-3.136.032C397.952 70.016 333.088 16 256 16c-64.032 0-121.504 38.112-146.688 96.032C48.8 113.472 0 163.136 0 224c0 61.76 50.24 112 112 112h304c52.928 0 96-43.072 96-96s-43.072-96-96-96z'}
        fill={'#E3F2FD'}
      />
      <path
        d={'M130.848 374.912C120.352 390.048 96 427.52 96 448c0 26.464 21.536 48 48 48s48-21.536 48-48c0-20.48-24.352-57.952-34.848-73.088-5.952-8.64-20.352-8.64-26.304 0z'}
        fill={'#2196F3'}
      />
      <path
        d={'M391.392 400c-4.384-7.68-14.208-10.304-21.856-5.856L352 404.288V384c0-8.832-7.168-16-16-16s-16 7.168-16 16v20.288l-17.568-10.144c-7.616-4.416-17.408-1.792-21.856 5.856-4.416 7.648-1.792 17.44 5.856 21.856l17.6 10.144-17.6 10.144c-7.648 4.416-10.272 14.208-5.856 21.856a16.04 16.04 0 0013.856 8c2.72 0 5.472-.672 8-2.144L320 459.712V480c0 8.832 7.168 16 16 16s16-7.168 16-16v-20.288l17.568 10.144a15.81 15.81 0 008 2.144c5.504 0 10.912-2.88 13.856-8 4.448-7.648 1.824-17.44-5.856-21.856L367.968 432l17.6-10.144c7.648-4.416 10.272-14.208 5.824-21.856z'}
        fill={'#CFD8DC'}
      />
    </svg>
  );
}

const MemoRainySnow = React.memo(RainySnow);
export default MemoRainySnow;
