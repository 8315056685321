import * as React from 'react';

import { find, some } from 'lodash';


/**
 * Return a boolean that indicates if at least one of passed child as declared type
 *
 * @param children Children to check
 * @param type Type to check
 */
export function someByType(
  children: React.ReactNode | React.ReactNode[],
  type: React.ElementType | ((node: React.ReactNode) => React.ElementType)
): boolean {
  return some(React.Children.toArray(children), { type });
}


/**
 * Return the first child of declared type
 *
 * @param children Children to iterate
 * @param type Type to check
 */
export function findByType(
  children: React.ReactNode | React.ReactNode[],
  type: React.ElementType | ((node: React.ReactNode) => React.ElementType)
): React.ReactNode {
  return find(React.Children.toArray(children), { type });
}


/**
 * Check if children is nil
 *
 * @param children Children to check
 */
export function isNil(children: React.ReactNode | React.ReactNode[]): children is null | undefined {
  return children === null || children === undefined || (Array.isArray(children) && children.length === 0);
}
