export function classByKey(value: any, className: string, alternativeClassName?: string): string | undefined {
  if (value) {
    return className;
  }

  if (alternativeClassName) {
    return alternativeClassName;
  }

  return undefined;
}


export function classByValue(value: any): string | undefined {
  if (typeof value === 'string') {
    return value.replace(/\s/g, '-');
  }

  return undefined;
}


export function isValue(value: any): string | undefined {
  if (typeof value === 'string' || typeof value === 'number') {
    return `is-${value.toString().replace(/\s/g, '-')}`;
  }

  return undefined;
}


export function classByPattern(value: any, pattern: string, replacer: string = '%value%'): string | undefined {
  if (typeof value === 'string' || typeof value === 'number') {
    return pattern.replace(new RegExp(replacer, 'g'), value.toString().replace(/\s/g, '-'));
  }

  return undefined;
}
