import * as React from 'react';

import EventStack from '@semantic-ui-react/event-stack';
import keyboardKey from 'keyboard-key';

import { invoke } from 'lodash';

import { AutoControlledComponent } from '@appbuckets/react-autocontrolled-component';

import doesNodeContainClick from '../../utils/doesNodeContainClick';
import { handleRef } from '../../utils/refUtils';
import Ref from '../Ref/Ref';

import PortalInner from './PortalInner';


/* --------
 * Component Props
 * -------- */
export type PortalShowEvent = (event: React.MouseEvent<HTMLElement>, props: PortalProps) => void;

export type PortalMountEvent = (nothing: null, props: PortalProps) => void;

export interface PortalProps extends StrictPortalProps {
  [key: string]: any;
}

export interface StrictPortalProps {
  /** Primary Portal Content */
  children?: React.ReactNode;

  /** Controls if the Portal should close when document is clicked */
  closeOnDocumentClick?: boolean;

  /** Controls if the Portal should close when escape key is pressed */
  closeOnEscape?: boolean;

  /** Controls if then Portal should close when mousing out of the portal */
  closeOnPortalMouseLeave?: boolean;

  /** Controls if the Portal should close on blur of the trigger */
  closeOnTriggerBlur?: boolean;

  /** Controls if the Portal should close on trigger click */
  closeOnTriggerClick?: boolean;

  /** Controls if the Portal should close on mousing out of the trigger */
  closeOnTriggerMouseLeave?: boolean;

  /** Initial open value */
  defaultOpen?: boolean;

  /** Event pool namespace */
  eventPool?: string;

  /** The node where the portal should mount */
  mountNode?: HTMLElement;

  /** Milliseconds to wait before opening on mouse over */
  mouseEnterDelay?: number;

  /** Milliseconds to wait before closing on mouse leave */
  mouseLeaveDelay?: number;

  /** Called when a close event happens */
  onClose?: PortalShowEvent;

  /** Called when the Portal is mounted on DOM */
  onMount?: PortalMountEvent;

  /** Called when an open event happens */
  onOpen?: PortalShowEvent;

  /** Called when the portal is unmounted from the DOM */
  onUnmount?: PortalMountEvent;

  /** Controls if the Porta is displayed */
  open?: boolean;

  /** Controls if the Portal should open on trigger click */
  openOnTriggerClick?: boolean;

  /** Controls if the Portal should open on trigger focus */
  openOnTriggerFocus?: boolean;

  /** Controls if the Portal should open on trigger mouse enter */
  openOnTriggerMouseEnter?: boolean;

  /** The element to be rendered in-place where the portal is defined */
  trigger?: React.ReactElement;

  /** Called with a ref to the trigger node */
  triggerRef?: React.Ref<HTMLElement>;
}


/* --------
 * Component Definition
 * -------- */
export default class Portal extends AutoControlledComponent<Pick<StrictPortalProps, 'open'>, PortalProps> {

  static defaultProps: Partial<PortalProps> = {
    closeOnDocumentClick: true,
    closeOnEscape       : true,
    eventPool           : 'default',
    openOnTriggerClick  : true
  };

  static autoControlledProps = [ 'open' ];

  contentRef = React.createRef<HTMLElement>();

  triggerRef = React.createRef<HTMLElement>();

  latestDocumentMouseEvent: MouseEvent | null = null;

  mouseEnterTimer: number | null = null;

  mouseLeaveTimer: number | null = null;


  /* --------
   * Lifecycle Event
   * -------- */
  public componentWillUnmount() {
    if (this.mouseEnterTimer) {
      window.clearTimeout(this.mouseEnterTimer);
    }

    if (this.mouseLeaveTimer) {
      window.clearTimeout(this.mouseLeaveTimer);
    }
  }


  /* --------
   * Document Event Handlers
   * -------- */
  handleDocumentMouseDown = (e: Event) => {
    this.latestDocumentMouseEvent = e as MouseEvent;
  };

  handleDocumentClick = (e: Event) => {
    const { closeOnDocumentClick } = this.props;

    const currentMouseDownEvent = this.latestDocumentMouseEvent;
    this.latestDocumentMouseEvent = null;

    /**
     * Ignore the click if there's no Portal
     * or the event happened in trigger, or the event
     * is originated in Portal but ended outside, or the event
     * happened in the portal
     */
    if (
      !this.contentRef.current
      || doesNodeContainClick(this.triggerRef.current!, e as MouseEvent)
      || (
        currentMouseDownEvent
        && doesNodeContainClick(this.contentRef.current, currentMouseDownEvent)
        || doesNodeContainClick(this.contentRef.current, e as MouseEvent)
      )
    ) {
      return;
    }

    if (closeOnDocumentClick) {
      this.close(e as MouseEvent);
    }
  };

  handleEscapeKey = (e: Event) => {
    const { closeOnEscape } = this.props;

    if (!closeOnEscape) {
      return;
    }

    if (keyboardKey.getCode(e as KeyboardEvent) !== keyboardKey.Escape) {
      return;
    }

    this.close(e as KeyboardEvent);
  };


  /* --------
   * Component Event Handlers
   * -------- */
  handlePortalMouseLeave = (e: Event) => {
    const {
      closeOnPortalMouseLeave,
      mouseLeaveDelay
    } = this.props;

    if (!closeOnPortalMouseLeave) {
      return;
    }

    // Avoid Portal close if event is triggered by children
    if (e.target !== this.contentRef.current) {
      return;
    }

    this.mouseLeaveTimer = this.closeWithTimeout(e as MouseEvent, mouseLeaveDelay);
  };

  handlePortalMouseEnter = () => {
    const { closeOnPortalMouseLeave } = this.props;

    if (!closeOnPortalMouseLeave) {
      return;
    }

    if (this.mouseLeaveTimer) {
      clearTimeout(this.mouseLeaveTimer);
    }
  };

  handleTriggerBlur = (e: MouseEvent, ...rest: any[]) => {
    const {
      trigger,
      closeOnTriggerBlur
    } = this.props;

    // Invoke original trigger event handler
    invoke(trigger, 'props.onBlur', e, ...rest);

    // Do not close if focus is given to the portal
    const target = e.relatedTarget || document.activeElement;
    const didFocusPortal = invoke(this.contentRef.current, 'contains', target);

    if (!closeOnTriggerBlur || didFocusPortal) {
      return;
    }

    this.close(e);
  };

  handleTriggerClick = (e: MouseEvent, ...rest: any[]) => {
    const {
      trigger,
      closeOnTriggerClick,
      openOnTriggerClick
    } = this.props;

    const { open } = this.state;

    // Invoke original trigger event handler
    invoke(trigger, 'props.onClick', e, ...rest);

    // Toggle Portal
    if (open && closeOnTriggerClick) {
      this.close(e);
    }
    else if (!open && openOnTriggerClick) {
      this.open(e);
    }
  };

  handleTriggerFocus = (e: MouseEvent, ...rest: any[]) => {
    const {
      trigger,
      openOnTriggerFocus
    } = this.props;

    // Invoke original trigger event handler
    invoke(trigger, 'props.onFocus', e, ...rest);

    if (!openOnTriggerFocus) {
      return;
    }

    this.open(e);
  };

  handleTriggerMouseLeave = (e: MouseEvent, ...rest: any[]) => {
    if (this.mouseEnterTimer) {
      clearTimeout(this.mouseEnterTimer);
    }

    const {
      trigger,
      closeOnTriggerMouseLeave,
      mouseLeaveDelay
    } = this.props;

    // Invoke original trigger event handler
    invoke(trigger, 'props.onMouseLeave', e, ...rest);

    if (!closeOnTriggerMouseLeave) {
      return;
    }

    this.mouseLeaveTimer = this.closeWithTimeout(e, mouseLeaveDelay);
  };

  handleTriggerMouseEnter = (e: MouseEvent, ...rest: any[]) => {
    if (this.mouseLeaveTimer) {
      clearTimeout(this.mouseLeaveTimer);
    }

    const {
      trigger,
      mouseEnterDelay,
      openOnTriggerMouseEnter
    } = this.props;

    // Invoke original trigger event handler
    invoke(trigger, 'props.onMouseEnter', e, ...rest);

    if (!openOnTriggerMouseEnter) {
      return;
    }

    this.mouseEnterTimer = this.openWithTimeout(e, mouseEnterDelay);
  };

  handleMount = () => {
    invoke(this.props, 'onMount', null, this.props);
  };

  handleUnmount = () => {
    invoke(this.props, 'onUnmount', null, this.props);
  };

  handleTriggerRef = (component: HTMLElement) => {
    (this.triggerRef as React.MutableRefObject<HTMLElement>).current = component;
    handleRef(this.props.triggerRef, component);
  };


  /* --------
   * Portal Controllers
   * -------- */
  open = (e: MouseEvent) => {
    // Invoke the handler if exists
    invoke(this.props, 'onOpen', e, this.props);
    // Open the Portal
    this.setState({ open: true });
  };

  openWithTimeout = (e: MouseEvent, delay?: number): number => {
    // Force event clone
    const eventClone = { ...e };
    // Set the open timeout
    return window.setTimeout(() => this.open(eventClone), delay ?? 0);
  };

  close = (e: MouseEvent | KeyboardEvent) => {
    // Invoke the handler if exists
    invoke(this.props, 'onClose', e, this.props);
    // Close the Portal
    this.setState({ open: false });
  };

  closeWithTimeout = (e: MouseEvent | KeyboardEvent, delay?: number): number => {
    // Force event clone
    const eventClone = { ...e };
    // Set the close timeout
    return window.setTimeout(() => this.close(eventClone), delay ?? 0);
  };


  public render() {
    const {
      children,
      eventPool,
      mountNode,
      trigger,
      openOnTriggerClick,
      closeOnTriggerClick
    } = this.props;

    const {
      open
    } = this.state;

    return (
      <React.Fragment>

        {/* Portal */}
        {open && (
          <React.Fragment>
            <PortalInner
              innerRef={this.contentRef}
              mountNode={mountNode}
              onMount={this.handleMount}
              onUnmount={this.handleUnmount}
            >
              {children}
            </PortalInner>

            <EventStack
              name={'mouseleave'}
              on={this.handlePortalMouseLeave}
              pool={eventPool}
              target={this.contentRef}
            />

            <EventStack
              name={'mouseenter'}
              on={this.handlePortalMouseEnter}
              pool={eventPool}
              target={this.contentRef}
            />

            <EventStack
              name={'mousedown'}
              on={this.handleDocumentMouseDown}
              pool={eventPool}
            />

            <EventStack
              name={'click'}
              on={this.handleDocumentClick}
              pool={eventPool}
            />

            <EventStack
              name={'keydown'}
              on={this.handleEscapeKey}
              pool={eventPool}
            />
          </React.Fragment>
        )}

        {/* Render Trigger */}
        {trigger && (
          <Ref innerRef={this.handleTriggerRef}>
            {React.cloneElement(trigger, {
              onBlur      : this.handleTriggerBlur,
              onClick     : openOnTriggerClick || closeOnTriggerClick || trigger?.props?.onClick
                ? this.handleTriggerClick
                : undefined,
              onFocus     : this.handleTriggerFocus,
              onMouseLeave: this.handleTriggerMouseLeave,
              onMouseEnter: this.handleTriggerMouseEnter
            })}
          </Ref>
        )}

      </React.Fragment>
    );
  }
}
