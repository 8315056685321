import * as React from 'react';


function LightCloudyNight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox={'0 0 512 512'} width={'1em'} height={'1em'} {...props}>
      <path
        d={'M443.136 363.328c-4.288-5.28-11.488-7.232-17.824-4.96C407.552 364.736 388.288 368 368 368c-97.056 0-176-78.976-176-176 0-58.4 28.832-112.768 77.12-145.472a16.023 16.023 0 006.624-16.832c-1.472-6.432-6.752-11.296-13.312-12.256C254.944 16.352 247.488 16 240 16 107.648 16 0 123.648 0 256s107.648 240 240 240c84 0 160.416-42.688 204.352-114.176 3.552-5.792 3.04-13.184-1.216-18.496z'}
        fill={'#B0BEC5'}
      />
      <path
        d={'M427.872 176.096C413.664 120.288 363.008 80 304 80c-45.6 0-87.968 24.8-110.752 64C140.224 142.656 96 186.656 96 240c0 52.928 43.072 96 96 96h240c44.128 0 80-35.872 80-80 0-45.472-38.272-81.6-84.128-79.904z'}
        fill={'#E3F2FD'}
      />
    </svg>
  );
}

const MemoLightCloudyNight = React.memo(LightCloudyNight);
export default MemoLightCloudyNight;
