import * as React from 'react';


function Humidity(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox={'0 0 511.448 511.448'} width={'1em'} height={'1em'} {...props}>
      <path
        d={'M344.556 112.216C318.38 78.84 291.34 44.344 269.516 7.32c-5.76-9.76-21.824-9.76-27.552 0-21.856 37.024-48.896 71.52-75.072 104.896-53.056 67.68-103.168 131.616-103.168 207.232 0 105.888 86.112 192 192 192s192-86.112 192-192c0-75.616-50.112-139.552-103.168-207.232z'}
        fill={'#2196F3'}
      />
      <g fill={'#FAFAFA'}>
        <path d={'M207.724 287.448c-26.464 0-48-21.536-48-48s21.536-48 48-48 48 21.536 48 48-21.536 48-48 48zm0-64c-8.832 0-16 7.168-16 16s7.168 16 16 16 16-7.168 16-16-7.168-16-16-16zM303.724 447.448c-26.464 0-48-21.536-48-48s21.536-48 48-48 48 21.536 48 48-21.536 48-48 48zm0-64c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zM175.724 415.448a16.07 16.07 0 01-11.328-4.672c-6.24-6.24-6.24-16.384 0-22.624l160-160c6.24-6.24 16.384-6.24 22.624 0s6.24 16.384 0 22.624l-160 160a15.943 15.943 0 01-11.296 4.672z'} />
      </g>
    </svg>
  );
}

const MemoHumidity = React.memo(Humidity);
export default MemoHumidity;
